<template>
  <div class="agreement-wrapper">
    <div class="agreement-status" :class="`agreement-status_${status.color}`" />
    <div class="agreement">
      <div class="agreement__header">
        <div class="agreement__title">
          {{ agreementTitle }}
        </div>
        <esmp-tag
          v-if="status.text"
          class="agreement__status-tag"
          :color="status.color"
          is-status
        >
          {{ status.text }}
        </esmp-tag>
      </div>
      <div class="agreement__info">
        <div class="agreement__info-item">
          <div class="agreement__info-item-label">
            Дата начала
          </div>
          <div class="agreement__info-item-value">
            {{ startDateTime | dateFormat }}
          </div>
        </div>
        <div class="agreement__info-item">
          <div class="agreement__info-item-label">
            Тип согласования
          </div>
          <div class="agreement__info-item-value">
            {{ type }}
          </div>
        </div>
      </div>
      <div class="agreement__members members">
        <div
          v-for="item in members"
          :key="item.email"
          class="agreement__member member ticket-info__link"
          :title="item.caption"
          @click="showUserInfoModal(item)"
        >
          <div
            class="member__status"
            :class="`member__status--${item.statusKebabed}`"
          >
            <esmp-user-avatar :username="item.fullName" class="member__avatar" />

            <div class="member__status-icon-wrapper">
              <esmp-icon class="member__status-icon member__status-icon--check" name="check-small" />
              <esmp-icon class="member__status-icon member__status-icon--clock" name="clock-small" />
              <esmp-icon class="member__status-icon member__status-icon--close" name="close-small" />
              <esmp-icon class="member__status-icon member__status-icon--minus" name="minus-small" />
            </div>
          </div>

          <div class="member__info">
            <div class="member__name">
              {{ nameWithInitials(item.fullName) }}
            </div>
            <div class="member__email">
              {{ item.email }}
            </div>
          </div>
        </div>
      </div>
      <div class="agreement__actions">
        <esmp-button
          class="agreement__action"
          v-if="showReconcilingButton"
          size="small"
          @click="$emit('show-reconciling-modal')
          "
        >
          Добавить согласующего
        </esmp-button>
        <esmp-button
          v-if="showRecommenderButton"
          size="small"
          @click="$emit('show-recommender-modal')"
        >
          Добавить рекомендателя
        </esmp-button>
        <esmp-button
          class="agreement__action agreement__action--right"
          v-if="showResultButton"
          size="small"
          @click="$emit('show-result-modal')"
        >
          Результат согласования
        </esmp-button>
      </div>
    </div>
  </div>
</template>

<script>
import Hub from '@/plugins/event-hub';
import { nameWithInitials } from '@/utils/userInitials';

export default {
  name: 'AgreementsItem',
  props: {
    index: {
      type: [String, Number],
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    status: {
      type: Object,
      default: undefined,
    },
    startDateTime: {
      type: [String, Number, Date, Object],
      default: undefined,
    },
    type: {
      type: String,
      default: undefined,
    },
    members: {
      type: Array,
      default: undefined,
    },
    showReconcilingButton: {
      type: Boolean,
    },
    showResultButton: {
      type: Boolean,
    },
    showRecommenderButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    agreementTitle() {
      return this.title || `Согласование ${this.index}`;
    },
  },
  methods: {
    nameWithInitials,
    showUserInfoModal(user) {
      Hub.$emit('show-user-info-modal', user);
    },
  },
};
</script>

<style lang="scss">
.agreement {
  &-wrapper {
    margin: 15px 0;
    position: relative;
  }
  position: relative;
  z-index: 2;
  min-height: 80px;
  padding: 16px 24px;
  background: #FFFFFF;
  border: 1px solid #E8E8EE;
  border-radius: 16px;

  @include for-size(phone-portrait-down) {
    padding: 16px;
  }

  &-status {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -8px;
    width: 24px;
    height: 76px;
    border-radius: 8px;
    background-color: #f5f5f5;

    &_blue {
      background-color: #2F9AFF;
    }

    &_gray {
      background-color: #737882;
    }

    &_purple {
      background-color: $color-client-portal-logo;
    }

    &_yellow {
      background-color: #FFB608;
    }

    &_green {
      background-color: #70DC9F;
    }

    &_red {
      background-color: #FF4747;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include for-size(phone-portrait-down) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 4px;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    @include for-size(phone-portrait-down) {
      margin-top: 4px;
    }
  }
  &__info {
    &-item {
      margin: 6px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      &:first-child {
        margin: 0;
      }
      &-label {
        width: 115px;
        color: #797E8B;
      }
    }
  }
  &__actions {
    margin-top: $base-gutter;
    display: flex;
    gap: $base-gutter;
  }
  &__action {
    display: block;

    &--right {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
.members {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;

  @include for-size(phone-portrait-down) {
    display: block;
  }
}
.member {
  $member: &;

  margin: 10px 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__status {
    margin-right: 8px;
    background: #fff;
    padding: 2px 2px 14px;
    border: 2px solid transparent;
    border-radius: 20px;
    position: relative;

    &--denied,
    &--cancelled-by-customer,
    &--cancelled-by-executor {
      border-color: $color-approve-red;

      #{$member}__status-icon-wrapper {
        background: $color-approve-red;
      }

      #{$member}__status-icon--close {
        display: block;
        color: $color-error-day;
      }
    }

    &--future,
    &--closed,
    &--pending {
      border-color: $color-grayscale-10;

      #{$member}__status-icon-wrapper {
        background: $color-grayscale-10;
      }
    }

    &--future,
    &--pending {
      #{$member}__status-icon--clock {
        display: block;
        color: $color-black-op-50;
      }
    }

    &--closed {
      #{$member}__status-icon--minus {
        display: block;
        color: $color-black-op-50;
      }
    }

    &--approved {
      border-color: $color-approve-green;

      #{$member}__status-icon-wrapper {
        background: $color-approve-green;
      }

      #{$member}__status-icon--check {
        display: block;
        color: $color-success-day;
      }
    }
  }

  &__status-icon-wrapper {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid $color-white;
    padding: 3px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__status-icon {
    width: 100%;
    height: 100%;
    display: none;
  }

  &__info {

  }
  &__name {
    font-size: 12px;
    line-height: 16px;
  }
  &__email {
    font-size: 10px;
    line-height: 12px;
    color: #797E8B;
  }

  &__avatar {
    width: 24px;
    height: 24px;
  }
}
</style>
